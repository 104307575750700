<template>
	<div class="page-status-progressing">
		<div class="custom-headline">入驻申请</div>
		<el-card>
			<Step v-model="stepActiveIndex"></Step>
			<!-- 审核中 -->
			<div class="inner-box info" v-if="verifyStatus == 1">
				<img :src="assets.img.progressing" class="icon">
				<div>您的入驻资料已提交成功，请等待审核</div>
			</div>
			<!-- 审核成功 -->
			<div class="inner-box success" v-if="verifyStatus == 2">
				<img :src="assets.img.success" class="icon">
				<div class="text-box">您提交的资料已经审核成功，请登录<div class="link">m.lohashow.com<div class="mask"
							@click="jump2otherweb"></div>
					</div>进行合同签订同时电话联系招商人员进行沟通相关合同事宜
				</div>
			</div>
			<!-- 审核失败 -->
			<div class="inner-box error" v-if="verifyStatus == 9">
				<img :src="assets.img.error" class="icon">
				<div>您提交的资料未通过，<div class="link">点击此处<div class="mask" @click="jump2stepPreInfo"></div>
					</div>查看详情</div>
				<div class="reason-box">
					<div class="label">未通过原因如下:</div>
					<div class="reason-item" v-html="rejectMsg"></div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import Step from "../components/Step";
import { getRejectMsg } from "@/api/api-join-mall";
export default {
	components: {
		Step,
	},
	data() {
		return {
			// 动态改变1
			stepActiveIndex: [1, 3],
			assets: {
				img: {
					progressing: require("../assets/img/progressing.png"),
					success: require("../assets/img/progress_success.png"),
					error: require("../assets/img/progress_error.png"),
				},
			},
			verifyStatus: -1,
			rejectMsg: "",
		};
	},
	methods: {
		/**
		 * 获取进度
		 */
		_formatReason(reason) {
			if (!reason) return;
			return reason.replace(/\r\n/g, "<br/>");
		},
		/**
		 * 获取审核失败原因
		 */
		getRejectMsg() {
			getRejectMsg().then((res) => {
				if (res.data.code === 0) {
					this.rejectMsg = this._formatReason(res.data.data);
				}
			});
		},
		/**
		 * 点击 - 审核成功
		 */
		jump2otherweb() {
			window.open("http://m.lohashow.com");
		},
		/**
		 * 点击 - 审核失败
		 */
		jump2stepPreInfo() {
			this.$router.push({
				name: "StepPreInfo",
			});
		},
	},
	created() {
		this.verifyStatus = this.$route.query.status;
		if (this.verifyStatus == 2) {
			this.$set(this.stepActiveIndex, "0", 2);
		} else if (this.verifyStatus == 9) {
			this.getRejectMsg();
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.page-status-progressing {
	.inner-box {
		font-size: 18px;
		color: #666666;
		padding: 90px 0;
		text-align: center;
		.text-box {
			width: 50%;
			margin: 0 auto;
		}
		.icon {
			width: 180px;
			height: 180px;
			margin: 0 auto 23px;
		}
		.link {
			position: relative;
			display: inline-block;
			color: $color-theme;
			font-weight: 500;
			.mask {
				position: absolute;
				top: -10px;
				right: -10px;
				bottom: -10px;
				left: -10px;
				cursor: pointer;
				z-index: 9;
			}
		}
		&.info {
			.icon {
				color: #409eff;
			}
		}
		&.error {
			.icon {
				color: #f56c6c;
			}
			.reason-box {
				width: 280px;
				margin: 0 auto;
				text-align: left;
				.label {
					margin-top: 10px;
				}
				.reason-item {
					margin-top: 6px;
				}
			}
		}
	}
}
</style>